import React, { useRef, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function ScrollableBox({ children, height }) {
  const boxRef = useRef(null);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = 0;  // Scroll to the top
    }
  }, [children]);

  return (
    <Box ref={boxRef} sx={{ height: height ? `${height}` : '400px', width: '100%', overflowY: 'auto', p: 2 }}>
      <div style={{ whiteSpace: 'pre-line' }}>
        {children}
      </div>
    </Box>
  );
}

export default ScrollableBox;
