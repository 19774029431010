import WhiteBox from "./components/Whitebox";
import ScrollableBox from "./components/ScrollableBox";
import Title from "./components/Title";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Center from "./components/Center";
import { useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FollowUpScheduleTable from "./components/FollowUpScheduleTable";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Box from "@mui/material/Box";


export default function App({ id }) {


  const [addVisible, setAddVisible] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [noteBody, setNoteBody] = useState("");
  const [followUps, setFollowUps] = useState([]);
  const [refreshFollowups, setRefreshFollowups] = useState(null);

  const toggleAddVisible = () => {
    setAddVisible(!addVisible);
  };
  const handleDateTimeChange = (dateTime) => {
    setSelectedDateTime(dateTime);
  };

  const getFollowUps = async () => {

    setFollowUps(data);
  };

  useEffect(() => {
    getFollowUps();
  }, [refreshFollowups]);

  let data = [
    {
      date: "September 1, 2023",
      note_body: "Meeting was productive and we discussed future plans.",
      user_first_name: "John",
      datetime_appointment_set: "September 10, 2023 10:30 AM UTC",
    },
    {
      date: "September 2, 2023",
      note_body: "Initial introductory meeting with potential partner.",
      user_first_name: "Jane",
      datetime_appointment_set: "September 11, 2023 2:00 PM UTC",
    },
    {
      date: "September 3, 2023",
      note_body: "Reviewed the latest product updates and feedback.",
      user_first_name: "Doe",
      datetime_appointment_set: "September 12, 2023 3:15 PM UTC",
    },
    {
      date: "September 4, 2023",
      note_body: "Brainstorming session for the next marketing campaign.",
      user_first_name: "Alice",
      datetime_appointment_set: "September 13, 2023 9:00 AM UTC",
    },
    {
      date: "September 5, 2023",
      note_body: "Received valuable insights about market trends.",
      user_first_name: "Bob",
      datetime_appointment_set: "September 14, 2023 11:45 AM UTC",
    },
    {
      date: "September 6, 2023",
      note_body: "Outlined the strategies for Q4.",
      user_first_name: "Charlie",
      datetime_appointment_set: "September 15, 2023 1:30 PM UTC",
    },
    {
      date: "September 7, 2023",
      note_body: "Discussed possibilities of a merger.",
      user_first_name: "Denise",
      datetime_appointment_set: "September 16, 2023 4:30 PM UTC",
    },
    {
      date: "September 8, 2023",
      note_body: "Met with key stakeholders for feedback.",
      user_first_name: "Eve",
      datetime_appointment_set: "September 17, 2023 10:15 AM UTC",
    },
    {
      date: "September 9, 2023",
      note_body: "Explored expansion opportunities in the Asian market.",
      user_first_name: "Frank",
      datetime_appointment_set: "September 18, 2023 12:00 PM UTC",
    },
    {
      date: "September 10, 2023",
      note_body: "Finalized the annual budget and resource allocation.",
      user_first_name: "Grace",
      datetime_appointment_set: "September 19, 2023 10:00 AM UTC",
    },
  ];
  
  

  const handleFollowUpSubmit = async () => {

  };

  return (
    <WhiteBox>
      <Title>Followups</Title>

      <Center>

        {followUps.length > 0 ? 
                <ScrollableBox>
          <FollowUpScheduleTable setRefreshFollowups={setRefreshFollowups} data={followUps} />
        </ScrollableBox> : null
    
    }

      </Center>
      <Center>
        <FormControlLabel
          id="addNotes"
          sx={{ marginTop: 2 }}
          value="start"
          control={<Switch color="primary" checked={addVisible} />}
          label={<b>Add Follow Up</b>}
          labelPlacement="start"
          onChange={toggleAddVisible} // Toggle the state on change
        />
      </Center>

      {addVisible ? (
        <>
          <Box sx={{ marginTop: 3 }}>
            <Center>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Follow Up Date"
                  value={selectedDateTime}
                  onChange={handleDateTimeChange}
                />
              </LocalizationProvider>
            </Center>
          </Box>

          <Center>
            <TextField
              sx={{ marginTop: 5, width: "85%" }}
              id="standard-multiline-flexible"
              multiline
              maxRows={10}
              variant="standard"
              placeholder="Enter Follow Up notes..."
              value={noteBody}
              onChange={(event) => setNoteBody(event.target.value)} // Update the noteBody state on change
            />
          </Center>

          <Center>
            <Button
              sx={{ marginTop: 5, marginLeft: 5 }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleFollowUpSubmit()}
            >
              Add Follow Up
            </Button>
          </Center>
        </>
      ) : null}
    </WhiteBox>
  );
}
