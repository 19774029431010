import Box from '@mui/material/Box';



export default function Center({ children }){


return(


    <Box display="flex" justifyContent="center" alignItems="center">

        {children}

    </Box>

);


}