import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { Container, Paper } from "@mui/material";
import { humanReadableDate } from "../functions/functions.js";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteDialog from "./DeleteDialog.js";
import { useState } from "react";
import IconButton from '@mui/material/IconButton';
import { postData } from "../functions/functions.js";
import { apiScriptUrl } from "../functions/settings";

const truncateString = (str, maxLength) => {
  if (!str) {
    return ""; // Handle null, undefined, or empty strings
  }
  
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  }
  return str;
};

const formatText = (text) => {
  return text
    .split('_')
    .join(' ')
    .replace(/\w\S*/g, function(word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
};

const FollowUpScheduleTable = ({ data, setRefreshFollowups }) => {
  const isArrayData = Array.isArray(data);
  const entries = isArrayData ? data : [data]; // Ensure we have an array of entries
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState(null);

  const handleDelete = async () => {

    // Delete the entry from the database
    const data = {
      'follow_up_ID': entryToDelete,
    };

    let response = await postData(`${apiScriptUrl}?user_follow_ups&delete`, data);
    
    setDialogOpen(false);
  
    setRefreshFollowups(entryToDelete);
  };


  if (entries.length === 0) {
    return null;
  }

  const columnNames = Object.keys(entries[0]); // Get column names from the keys of the first entry

  return (
    <>
          <DeleteDialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => handleDelete()}
        deleteId={entryToDelete}
      />
    <TableContainer component={Paper}>
    <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columnNames.map((columnName) => (
              <TableCell key={columnName}>{formatText(columnName)}</TableCell>
            ))}
            <TableCell key="delete">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry, index) => (
            <TableRow key={index}>
              {columnNames.map((columnName) => (
                <>
                <TableCell key={columnName}>

                  { columnName === "follow_up_date" ? humanReadableDate(entry[columnName]) : entry[columnName]}

                </TableCell>
                
                </>
              ))}
              <TableCell key={index+"_delete"}><IconButton><DeleteForeverIcon onClick={() => {setEntryToDelete(entry.follow_up_ID); setDialogOpen(true)}} /></IconButton></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};

export default FollowUpScheduleTable;
